import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs';
import { LogService } from './services';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'entratus-crm';
  private _router = inject(Router);
  destroyRef = inject(DestroyRef);
  private _logService = inject(LogService);

  ngOnInit(): void {
    this.setupRouterEvents();
  }

  private setupRouterEvents() {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this._logService.logNavigation(window.location.href).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
      });
  }
}
