import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./core/components/main/main.routes').then((m) => m.mainRoutes),
  },
  {
    path: 'login',
    loadComponent: () => import('./components/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: '**',
    redirectTo: '/login',
    pathMatch: 'full',
  },
];
